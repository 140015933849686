<template>
    <div class="labor_rate_container">
        <div class="maintenance_banner">
            <h2>this page is currently under maintenance</h2>
            <a href="/"><img src="../../assets/cdi-logo-wheel-color.png"></a>
            <h4>we apologise for any inconvenience</h4>
        </div>  <div v-for="laborRate in laborRates" v-bind:key="laborRate.cdi_location_id" class="labor_rate_box" v-bind:class="{ 'text-danger': (laborRate.labor_rate > laborRateGoal) }">
            <span class='cdi_location'>{{ laborRate.cdi_location }}</span>
            <br>
            <span class='labor_rate'>{{ laborRate.labor_rate }}</span>
        </div>

    </div>
</template>

<script>
    import { store } from '@/store/Tool.store';

    export default {
        name: 'StatusBoard',
        data() {
            return {
                laborRates: [],
                laborRateGoal: 11
            }
        },
        created() {
            this.update();
            window.setInterval(() => {this.update()}, 5 * 60 * 1000);
        },
        methods: {
            update: function () {
                store.search('/labor/current_labor_rate').then(results => this.laborRates = results.labor_rates);
            }
        }
    }
</script>

<style scoped>
.maintenance_banner {
    z-index: 1000 !important;
    position: fixed;
    top: 20%;
    font-size: xx-large;
    text-align: center;
    width: 100%;
    background-color: #FFFFFF;
    height: 60%;
    padding: 10%;
}

.labor_rate_container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    background-color: #FFFFFF;
    z-index: 9001;
}

.labor_rate_box {
    border: 3px solid black;
    padding: 1rem 2rem 1rem 2rem;
    text-align: center;
    flex-grow: 1;
    overflow: hidden;
    opacity: .05;
}

.labor_rate {
    font-size: 22rem;
    line-height: 22rem;
}

.cdi_location {
    font-size: 7rem;
}

.header_center ul {
    visibility: hidden;
}

@media (max-width: 1699px) {
    .labor_rate {
        font-size: 16rem;
        line-height: 16rem;
    }

    .cdi_location {
        font-size: 6rem;
    }
}

@media (max-width: 460px) {
    .labor_rate {
        font-size: 8rem;
        line-height: 8rem;
    }

    .cdi_location {
        font-size: 3rem;
    }
}
</style>